import classNames from 'classnames';
import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { isCompanyEmployersPayrollRequiredSelector } from 'src/store/selectors/companySelector';
import {
  payrollEmployeeIdSelector,
  payrollMemberActionSelector,
  payrollRefSelector,
} from 'src/store/selectors/payrollSelector';

import { isLoggedInSelector, authRoleSelector, authRoleListSelector } from 'src/store/selectors/authSelector';
import {
  tenantBrandingLogoUrlSelector,
  tenantBrandingLogoAltSelector,
  tenantBrandingNameSelector,
} from 'src/store/selectors/tenantBrandingSelector';

import { Navigation } from '@itm/shared-frontend/lib/components';
import { clientPortalDashboardUrl } from '@itm/shared-frontend/lib/utils';
import { MenuIcon, PenscopeIcon, HideIcon } from '@itm/shared-frontend/lib/components/icons';
import type { NavigationLocalConfig } from '@itm/shared-frontend/lib/components/Navigation';

import Filters from 'src/components/Filters';

import { isAzureTargetPlatform } from 'src/utils/constants';

import { Role } from 'src/types';

import styles from './Sidebar.module.scss';

type Props = Readonly<{
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}>;

function Sidebar({ isActive, setIsActive }: Props) {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userRole = useSelector(authRoleSelector);
  const userRoleList = useSelector(authRoleListSelector);

  const brandingLogoUrl = useSelector(tenantBrandingLogoUrlSelector);
  const brandingLogoAlt = useSelector(tenantBrandingLogoAltSelector);
  const brandingName = useSelector(tenantBrandingNameSelector);

  const isCompanyEmployersPayrollRequired = useSelector(isCompanyEmployersPayrollRequiredSelector);
  const payrollRef = useSelector(payrollRefSelector);
  const employeeId = useSelector(payrollEmployeeIdSelector);
  const action = useSelector(payrollMemberActionSelector);

  const navigationLocalConfig = useMemo<NavigationLocalConfig<Role>>(
    () => [
      {
        name: 'All Products',
        section: 'products',
        path: clientPortalDashboardUrl,
        icon: () => <MenuIcon />,
      },
      {
        name: 'Employer Hub',
        section: 'products',
        path: RoutePath.root,
        isInternalRoute: true,
        icon: () => <PenscopeIcon />,
        children: [
          {
            section: 'common',
            name: 'Configuration',
            path: RoutePath.configurationRoot,
            isInternalRoute: true,
            role: { allowList: [Role.SuperAdmin, Role.Support, Role.ClientAdmin, Role.Analyst] },
            children: [
              {
                name: 'Template Definitions',
                path: RoutePath.configurationTemplateDefinitionRoot,
                role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst] },
              },
              {
                name: 'General Settings',
                path: RoutePath.configurationGeneralSettingsRoot,
              },
              {
                name: 'All Validations',
                path: RoutePath.configurationValidationsRoot,
              },
              {
                name: isCompanyEmployersPayrollRequired ? 'All Payrolls' : null,
                path: RoutePath.configurationPayrollsList,
              },
              {
                name: isCompanyEmployersPayrollRequired ? 'Add New Payroll' : null,
                path: RoutePath.configurationPayrollsAdd,
              },
            ],
          },
          {
            section: 'common',
            name: 'Data Management',
            path: RoutePath.dataManagementRoot,
            isInternalRoute: true,
            role: { allowList: [Role.SuperAdmin, Role.Support, Role.ClientAdmin, Role.Manager, Role.Analyst] },
            children: [
              {
                name: 'Data Upload History',
                path: RoutePath.dataManagementUploadHistoryList,
                role: { allowList: [Role.SuperAdmin, Role.Support, Role.ClientAdmin, Role.Manager, Role.Analyst] },
              },
              {
                name: 'Validation Helper',
                path: RoutePath.dataManagementValidationHelperList,
                role: { allowList: [Role.SuperAdmin, Role.Support, Role.ClientAdmin, Role.Manager, Role.Analyst] },
              },
              {
                name: 'Member Search',
                path: RoutePath.dataManagementMemberList,
                role: { allowList: [Role.SuperAdmin, Role.Support, Role.ClientAdmin, Role.Manager, Role.Analyst] },
              },
              {
                name: payrollRef || null,
                path: generatePath(RoutePath.dataManagementMemberViewEditRoot, {
                  employeeId,
                  action,
                }),
                children: [
                  {
                    name: 'Membership Details',
                    path: generatePath(RoutePath.dataManagementMemberViewEditMembershipDetails, {
                      employeeId,
                      action,
                    }),
                  },
                  {
                    name: 'Employment Details',
                    path: generatePath(RoutePath.dataManagementMemberViewEditEmploymentDetails, {
                      employeeId,
                      action,
                    }),
                  },
                  {
                    name: 'Contributions',
                    path: generatePath(RoutePath.dataManagementMemberViewEditContributions, {
                      employeeId,
                      action,
                    }),
                  },
                  {
                    name: 'Audit',
                    path: generatePath(RoutePath.dataManagementMemberViewEditAudit, {
                      employeeId,
                      action,
                    }),
                  },
                ],
              },
            ],
          },
          {
            section: 'common',
            name: 'Reporting',
            path: RoutePath.reportingRoot,
            isInternalRoute: true,
            role: { allowList: [Role.SuperAdmin, Role.Support, Role.ClientAdmin, Role.Manager, Role.Analyst] },
            children: [
              {
                name: 'Report History',
                path: RoutePath.reportingHistoryList,
                role: { allowList: [Role.SuperAdmin, Role.Support, Role.ClientAdmin, Role.Manager, Role.Analyst] },
              },
            ],
          },
          {
            name: 'Product Information',
            path: RoutePath.productInfo,
            role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst] },
          },
        ],
      },
    ],
    [action, employeeId, isCompanyEmployersPayrollRequired, payrollRef],
  );

  return (
    <aside
      className={classNames([
        styles.Wrapper,
        'is-flex is-flex-direction-column has-background-white is-relative',
        { [styles.IsActive]: isActive },
      ])}
      id="sidebar"
    >
      <header className={classNames(styles.Header, 'is-flex-shrink-0')}>
        <div className="columns is-align-items-center is-gapless mb-0">
          <div className="column is-narrow">
            <Link className={classNames(styles.Logo, 'has-icon')} to={RoutePath.root}>
              <img src={brandingLogoUrl} alt={brandingLogoAlt} />
            </Link>
          </div>
          {isActive && (
            <div className="column">
              <h2 className="title is-nowrap is-4 ml-4">{brandingName}</h2>
            </div>
          )}
        </div>
        {isActive && <Filters />}
      </header>
      {isLoggedIn && (
        <>
          <button
            className={classNames(styles.ToggleButton, 'has-icon', { 'is-active': isActive })}
            type="button"
            onClick={() => setIsActive(!isActive)}
            aria-controls="sidebar"
            aria-label={isActive ? 'Collapse sidebar' : 'Expand sidebar'}
          >
            <span className="icon">
              <HideIcon />
            </span>
          </button>
          {userRole && (
            <Navigation
              isActiveSidebar={isActive}
              localConfig={navigationLocalConfig}
              userRoleList={userRoleList}
              isAzureTargetPlatform={isAzureTargetPlatform}
              isCommonItemsCollapse
            />
          )}
        </>
      )}
    </aside>
  );
}

export default Sidebar;
