import classNames from 'classnames';
import { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector, useDispatch } from 'src/store';
import {
  selectedCompanyIdSelector,
  allCompanyOptionsSelector,
  isLoadingCompanySelector,
} from 'src/store/selectors/companySelector';
import { selectCompany } from 'src/store/slices/companySlice';

import { dropdownFilterOption } from '@itm/shared-frontend/lib/utils';
import Select, { SingleValue } from '@itm/shared-frontend/lib/components/react-select';

import { SelectOption } from 'src/types';

function Filters() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const companyOptions = useSelector(allCompanyOptionsSelector);
  const isLoadingCompanies = useSelector(isLoadingCompanySelector);

  const isFilterDisabled = useMemo(
    () =>
      Boolean(
        matchPath(RoutePath.configurationPayrollViewDetail, pathname) ||
          matchPath(RoutePath.configurationPayrollViewPeriod, pathname) ||
          matchPath(RoutePath.configurationPayrollEditDetail, pathname) ||
          matchPath(RoutePath.configurationPayrollEditPeriod, pathname) ||
          matchPath(RoutePath.configurationValidationViewDetail, pathname) ||
          matchPath(RoutePath.configurationValidationViewAudit, pathname) ||
          matchPath(RoutePath.configurationValidationEditDetail, pathname) ||
          matchPath(RoutePath.configurationValidationEditAudit, pathname) ||
          matchPath(`${RoutePath.dataManagementMemberViewEditRoot}/*`, pathname),
      ),
    [pathname],
  );

  const selectedCompanyOption = useMemo(
    () => companyOptions.find((option) => option.value === selectedCompanyId) || null,
    [companyOptions, selectedCompanyId],
  );

  const companyChangeHandler = (newOption: SingleValue<SelectOption>) => {
    if (newOption) {
      dispatch(selectCompany(newOption.value));
    }
  };

  return (
    <div className="is-flex is-justify-content-center pt-5">
      <div className="control is-fullwidth">
        <Select
          className={classNames('react-select', { 'is-loading': isLoadingCompanies })}
          classNamePrefix="react-select"
          value={selectedCompanyOption}
          options={companyOptions}
          aria-label="Company name"
          placeholder={companyOptions.length || isLoadingCompanies ? 'Company name' : 'No companies'}
          onChange={companyChangeHandler}
          isDisabled={isFilterDisabled || isLoadingCompanies || !companyOptions.length}
          isClearable={false}
          isSearchable={true}
          closeMenuOnSelect={true}
          filterOption={dropdownFilterOption}
        />
      </div>
    </div>
  );
}

export default Filters;
